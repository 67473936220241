.myaccount .form-image-container {
    margin: auto;
}

.myaccount .form-image {
    width: 192px;
    margin: auto;
}

.myaccount .form-image-options {
    text-align: center;
}

.login-modal-body header i {
    float: left;
    font-size: 1.2em;
    margin-top: 0.24em;
    margin-right: 0.5em;
}

.login-modal-body .login-button {
    display: block;
    margin: auto;
    min-width: 100px;
}

.facebook-button button {
    background-color: #4267B2;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    min-width: 210px;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
    color: white;
    box-shadow: none;
    border: 0;
}

.facebook-button span {
    cursor: pointer;
}

.register-invitation {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.register-invitation * {
    float: left;
    font-size: 1em;
}

.register-invitation button {
    padding: 0 5px 0 5px;
    margin: auto;
}

.signup button[type=submit] {
    margin: auto !important;
    display: block;
}

.forgotten-password-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5em;
}

.reset-password .modal-overlay .centered {
    margin-top: 6em;
    text-align: center;
}