@import 'bootstrap/dist/css/bootstrap.min.css';
@import "material-kit-pro-react.scss";
@import "./plugins/plugin-react-slick";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

// Rafala UK
$primary-color: #ee442b;
$secondary-color: #f3b12b;
// Enoteca
// $primary-color: #f3b12b;
// $secondary-color: #ee442b;

$preloader-inner-color: $primary-color;
$preloader-outer-color: rgba(0,0,0,0.4);

$header-height: 70px;

$res0: 600px; // Mobile
$res1: 1001px;
$res2: 1301px;
$res3: 1601px;
$res4: 1921px;

@import "animated-preloader";
@import "modal";
@import "overlay";
@import "radio";
@import "form-image";
@import "auth";
@import "cart";
@import "orders";
@import "quick-user";
@import "products-gallery";
@import "cookies-notification";

// Scrollbars
@media only screen and (min-width: $res0) {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    // Track
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }

    // Handle
    ::-webkit-scrollbar-thumb {
        background: #777; 
    }

    // Handle on hover
    ::-webkit-scrollbar-thumb:hover {
        background: $secondary-color; 
    }
}

body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //   sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14pt;
    font-family: 'Lato', sans-serif;
}

h1 {
    font-size: 1.8em;
    color: $primary-color;
}

h2 {
    font-size: 1.5em;
    color: $primary-color;
    text-align: center;
}

h3 {
    font-size: 1.4em;
}

a {
    color: #007bff;
}

a:hover {
    color: $primary-color;
}

.hidden {
    display: none;
}

.mobile-hidden, .desktop-hidden {
    display: none;
}

@media only screen and (max-width: $res0) {
    .desktop-hidden {
        display: block;
    }
}

@media only screen and (min-width: $res0) {
    .mobile-hidden {
        display: block;
    }
}

.margin-auto {
    margin: auto;
}

.display-flex {
    display: flex !important;
}

.social-list {
    display: block !important;
}

.uppercase {
    text-transform: uppercase;
}

@media only screen and (min-width: $res0) {
    .header-link, .header-link:hover {
        color: white;
        text-shadow: 1px 1px 4px #222;
    }

    .social-list-item {
        float: right !important;
    }
}

.line-through {
    text-decoration: line-through;
}

// Bootstrap mods

.modal-backdrop, .modal {
    z-index: 1101;
}

.rounded-button {
    border-radius: 25px !important;
}

// Home Banner

.page-header {
    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        text-align: center;
        z-index: 1;
        
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    
    .footer {
        position: absolute;
        z-index: 5;
        top: 70%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        width: 100%;
        height: 30%;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding: 1em;

        .chevron-down button {
            background-color: $primary-color;
        }
        
        .chevron-down:hover button {
            background-color: $secondary-color;
        }
    }
}

.search-box {
    width: 300px;

    position: fixed !important;
    top: 100px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);

    .anchor {
        top: -100px;
    }

    > div {
        background-color: rgba(230, 230, 230, 0.8)
    }

    > div.Mui-focused, > div:hover {
        background-color: rgba(230, 230, 230, 1)
    }

    label, input, svg {
        color: black;
    }
}

@media only screen and (max-width: $res0) {
    .chevron-down {
        display: none;
    }

    .page-header {
        .content {
            justify-content: start;
            top: $header-height + 50px;
        }

        .footer {
            .search-box {
                width: 100%;
            }
        }
    }
}

.banner-title {
    color: $primary-color !important;
    text-shadow: 5px 5px 7px rgba(255, 255, 255, 0.35);
    font-weight: bold;
    font-size: 2em;
}

.banner-subtitle {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    // color: #3c3967;
    color: #000;
}

.banner-desc {
    p {
        color: black;
        text-shadow: 1px 1px 2px rgba(255,255,255,0.8);
        font-size: 0.9em;
        padding: 0 0.5em;
        font-weight: 400;
    }
}

.enoteca-theme {
    .banner-subtitle {
        color: white;
    }
    
    .banner-desc {
        color: white;
        max-width: 1000px;
    }
}

// End Home Banner

@media only screen and (max-width: $res0) {
    header {
        .logo {
            display: none
        }
    }

    .drawer {
        padding-top: 2em;

        .header-link {
            color: #212121
        }
    }
}

.container.page {
    max-width: 1600px;
    margin-top: 100px;
    margin-bottom: 20px;
}


header.modal-title h1 {
    font-size: 1.5em;
    color: $primary-color;
    margin-bottom: 1.2em;
}

header.modal-title i {
    color: $primary-color;
    font-size: 1.4em;
    margin-top: 0.2em;
    margin-right: 0.4em;
    float: left;
}

.site-footer {
    padding: 20px 10px !important;
    width: 100%;
}

.site-footer img {
    margin-left: 6px;
}

.site-footer .legal {
    text-align: center;
}

.site-footer .signature {
    padding-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
}

.menu-footer-image {
    margin-top: 15px;
    margin-bottom: 0px;
}

footer .brand a {
    color: #3C4858;
    height: 50px;
    padding: 15px 15px;
    font-family: Roboto Slab,Times New Roman,serif;
    font-weight: 700;
    line-height: 50px;
    margin-left: -15px;
    text-decoration: none;
}

footer .brand h6 {
    margin-top: 0;
}

footer .links {
    padding-top: 16px;
}

footer .row {
    width: 100%;
    float: left;
}

.developed-by {
    display: block;
    margin: auto;
    margin-top: 20px;
    float: none;
    display: inline-block;
}

.developed-by p {
    display: block;
    float:left;
    font-size: 0.8em;
    margin-top: 5px;
}

.developed-by a {
    display: block;
    float: left;
    height: 32px;
}

.legal-container, .page-container {
    margin: auto;
    padding: 80px 20px 20px 20px;
    max-width: 1600px;
    font-size: 1em;
    // font-size: 14pt;
    
    p {
        font-size: 1em;
        width: 100%;
    }

    hr {
        width: 100%;
    }
}

// .legal-container p, .page-container p {
//     font-size: 1em;
// }

.legal-container, .page-container {
    h1, h2, h3, h4, h5, ul {
        width: 100%;
    }
}

.contact-us {
    background-color: white;
    border-radius: 3px;
    margin-top: 2em;
}

.contact-us .image-box {
    display: flex;
    padding: 1em;
}

footer .brand {
    float: left!important;
    display: block;
}

@media only screen and (max-width: $res0) {
    footer .brand {
        width: 100%;
    }
}

.our-products p {
    font-size: 1.0em;
}

.selected-category-gallery {
    margin-top: 15px;
}

.logo {
    height: 48px;
}

.logo-big {
    height: 140px;
    margin: 1em auto;
    // margin-bottom: 1em;
}

@media only screen and (min-width: $res0) {
    .logo-big {
        height: 192px;
        // margin: auto;
        // margin-bottom: 1em;
    }
}

.brands-carousel {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    margin-top: 20px;
}
  
.brands-carousel h2 {
    color: $primary-color;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 35px;
}
  
.brands-carousel h3 {
    color: #333;
    font-size: 12pt;
    text-align: center;
    padding-top: 10px;
}
  
.brands-carousel .react-multi-carousel-list {
    width: 100%;
}
  
.brand-card {
    width: 120px;
    min-height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: auto;
    margin-left: auto;
}
  
.brands-carousel .image-wrapper {
    width: 120px;
    height: 120px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.brands-carousel img {
    width: 100%;
    /* height: 100%; */
}
  

// Contacts

.contacts input[type=radio] {
    margin-right: 10px;
}

.contacts h3 {
    float: left;
    margin-top: 5px;
    width: auto;
}

.contacts .delete-button {
    position: absolute;
    right: 0;
    color: #ee0000;
}

.contacts p {
    clear: both;
}

.contacts input[type=radio] {
    float: left;
    margin-top: 10px;
}

.insert-new-address-button {
    display: block;
    margin: auto;
}

.address-form button[type=submit] {
    float: right;
}

.address-form {
    position: relative;
}

// Rafala adjustments for cart

.cart-item .info p.small {
    white-space: normal;
    font-size: 0.7em;
    line-height: 1.5;
    color: #555;
}

.orders .payment-options {
    width: 100%;
    float: left;
}

.home-deliveries-banner {
    background-color: $primary-color;
    padding: 2em 0;
    margin: 1em 0;
    justify-content: center;
    cursor: pointer;

    a, p, h1, h2, h3, h4, h5, button {
        color: white;
    }

    // h3, h4, h5 {
    //     width: 100%;
    //     text-transform: uppercase;
    //     text-align: center;
    //     font-weight: bold;
    // }

    p {
        font-size: 25pt;
        font-weight: bold;
    }

    img {
        width: 100%;
        max-width: 200px;
    }

    .col-8 {
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: $res0) {
    .home-deliveries-banner {
        font-size: 13pt;
        text-align: center;

        p {
            font-size: 15pt;
            font-weight: bold;
        }
    }
}

.anchor {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
}

#homeDeliveriesBannerAnchor.anchor {
    top: -110px;
}

.order .payment-desc {
    float: left;
    width: 100%;
    margin-top: 0.5em;
}

.legal-container p .btn-link {
    padding-left: 0;
    padding-right: 0;
    padding-top: 3px;
    font-size: 0.9em;
}

.sub-categories-list {
    margin-bottom: 1em !important;
}

.back-to-bulk-button, .back-to-bulk-button:hover {
    background-color: $primary-color;
    color: white;
}

.MuiSnackbarContent-root {
    background-color: white !important;
    color: black !important;
}

.popup-card {
    color: black;
    max-width: 400px;

    .brand-logo-link, a {
        display: block;
        text-align: center;
    }

    p {
        font-size: 1.2em;
        text-align: center;
    }

    .actions {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .shop-link {
        margin-top: 1em;
    }
}

.add-to-cart-button {
    background: #222222;
    border: 1pt solid #444;
}

.add-to-cart-button:hover {
    background-color: $primary-color;
    border: 1pt solid $primary-color;
}