.with-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    background: rgba(255,255,255,0.85);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.with-overlay .animated-preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.with-overlay .centered {
    position: absolute;
    width: 100%;
    text-align: center;
}

.with-overlay .icon {
    font-size: 3em;
    display: block;
    text-align:center;
    margin: 0.2em;
}

.with-overlay .message {
    min-width: 250px;
}

.with-overlay button {
    clear: both;
    display: block;
    margin:auto;
    margin-top: 1em;
}