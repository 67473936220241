.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
	display: block;
	width: 100%;
	float: left;
	padding: 15px 5px 15px 5px;
	margin: 0;
	background: #FAFAFA;
	border-bottom: 1pt solid #ccc;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
	text-align: center;
	font-size: 12pt;
}

.top-bar button {
    width: 100px;
    height: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}