$card-product-img-height: 100px;
$card-product-details-img-height-mobile: 180px;
$card-product-details-img-height: 250px;

$menu-categories-z-index: 99;

$category-item-size: 120px;
$category-item-size-lg: 160px;

.menu-categories {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: auto;
    position: sticky;
    // top: $navbar-height;
    z-index: $menu-categories-z-index;
    background: white;
}

.menu-categories > div  { // .menu-category
    background-color: #999;
    height: $category-item-size;
    margin: 2px;

    flex-grow: 0;     /* do not grow   - initial value: 0 */
    flex-shrink: 0;   /* do not shrink - initial value: 1 */
    flex-basis: $category-item-size; /* width/height  - initial value: auto */
    overflow: hidden;
}

.menu-categories .menu-category  {
    background-color: #f1f1f1;
    // font-size: 30px;
    text-align: center;
    cursor: pointer;
    background-size: cover;
    background-color: #999;
    width: 100%;
    // height: 100%;
    overflow: hidden;
    border: 5px solid white;
}

.menu-category .overlay {
    display: block;
    float: left;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    margin: 0px;
}

.menu-category.selected {
    padding: 0;
    border: 5px solid $primary-color;
}

@media only screen and (max-width: $res0) {
    .menu-categories {
        overflow-x: auto; /* [2] */
        -webkit-overflow-scrolling: touch; /* [3] */
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .menu-categories::-webkit-scrollbar {
        display: none;
    }
}

@media only screen and (min-width: $res2) {
    .menu-categories > div {
        height: $category-item-size-lg;
        flex-basis: $category-item-size-lg;
    }
}

.menu-category p {
    color: white;
    margin-top: 50%;
    transform: translateY(-50%);
    user-select: none;
}

.product-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: flex-start;
}

.product-list .card-product {
    flex-basis: 180px;
    margin: 5px 5px 5px;
}

@media only screen and (max-width: $res0) {
    .product-list {
        justify-content: space-around;
    }

    .product-list .card-product {
        flex-basis: 46.5%;
    }
}

.card-product .img-wrap {
    margin-top: 10px;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    position: relative;
    height: $card-product-img-height;
    width: 100%;
    text-align: center;
    background-size: contain;
    background-position: center center;
    background-color: #fff;
    background-repeat: no-repeat;
}

.card-product .img-wrap img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: $card-product-img-height;
    //max-width: 100%;
    transform: translate(-50%, -50%);
}

.card-product .img-wrap img.placeholder {
    opacity: 0.5;
}

.card-product .info-wrap {
    overflow: hidden;
    padding: 5px 5px 0 5px;
    margin-top: 5px;
    flex-grow: 1; // Takes all the room
}

.card-product .info-wrap .price {
    margin-top:auto;
    text-align: center;
}

.card-product .info-wrap .product-flag {
    margin-top: 5px;
    margin-left: 4px;
    border: 1pt solid #aaa;
    padding: 5px;
    border-radius: 3pt;
}

.card-product .bottom-wrap {
    border-top: 1px solid #eee;
    text-align: center;
}

.card-product .bottom-wrap > p {
    margin: 5px 0;
}

.card-product.details .bottom-wrap > .btn {
    margin-top: 11px;
}

.card-product .bottom-wrap > .btn {
    font-size: 0.8em;
    float: none !important;
    margin-top: 0px;
    margin-bottom: 11px;
}

.card-product .bottom-wrap .counter {
    font-size: 1.0em;
}

.card-product .bottom-wrap .counter button {
    font-size: 1.2em;
}

.card-product.details {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
}

.card-product.details .img-wrap {
    height: $card-product-details-img-height;
}

.card-product.details .extras-wrap {
    padding: 5px 5px;
}

.card-product.details .extras-wrap h4 {
    text-align: center;
    color: $secondary-color;
}

.card-product.details h2 {
    color: $primary-color;
    margin-left: 4px;
}

.card-product.details .ingredients {
    margin-left: 4px;
}

.card-product.details .desc {
    text-align: center;
    margin-bottom: 0;
}

.card-product.details input[type=text] {
    width: 96%;
    margin: auto;
}

.card-product .info-wrap h4.title {
    text-align: center;
    width: 100%;
    font-size: 1.10rem; //1.125rem;
}

.card-product .price {
    margin: 0; 
}

.card-product.details > .price {
    font-family: Arial;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
}

.selected-category-gallery .animated-preloader {
    margin-top: 1em;
}

.preloader-box {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.category-details {
    background-color: white;
    border-radius: 3px;
    padding: 15px 0;
    margin: 5px;
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    border: 1pt solid #ccc;
    box-shadow: 3px 3px 6px #cacaca;
}

.category-details img {
    max-width: 100%;
    max-height: 256px;
    margin: auto;
    display: block;
}

.category-details p {
    font-size: 1.0em;
}

.category-details h2 {
    text-align: left;
}

.featured-products {
    .items-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: auto;

        figure {
            flex: 0 0 250px;
            margin: 5px;
        }
    }
}

@media only screen and (max-width: $res0) {
    .featured-products {
        .items-row {
            figure {
                flex: 0 0 45%;
                margin: 5px;
            }
        }
    }
}