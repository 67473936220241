.orders {
    position: relative;
    overflow: auto;
    font-family: 'Noto Sans JP', sans-serif;
    background-color: white;
}

.orders h2 {
    text-align: center;
}

.orders .update-orders-button {
    position: absolute;
    top: 25px;
    left: 5px;
}

.orders .modal-overlay {
    min-height: 200px;
}

.orders .status-container {
    display: block;
    width: 100%;
    float: left;
    clear: both;
    margin-bottom: 10px;
}

.order {
    float: left;
    width: 100%;
    position: relative;
}

.order .items .item {
    clear: both;
    font-size: 0.9em;
    color: #5a5a5a;
}

.order .note {
    float: left;
    clear: both;
    width: 100%;
    margin-top: 0.5em;
}

.order .label {
    width: 100px;
    float: left;
    font-weight: bold;
    color: $secondary-color;
}

.order .created-at {
    float: left;
    width: 180px;
}

.order .status {
    float: left;
    cursor: default;
}

@media only screen and (max-width: $res0) {
    .order .label {
        width: 30%;
    }
}

.order .status {
    clear: both;
    border: 1pt solid #444;
    border-radius: 25px;
    padding: 2px 15px 4px 15px;
    margin-top: 5px;
}

.order .status.index-2 {
    font-weight: bold;
    color: #ffa319;
    border-color: #ffa319;
}

.order .status.index-3 {
    font-weight: bold;
    color: #31c54a;
    border-color: #31c54a;
}

.order .status.index-5 { // Cancelled by the restaurant
    font-weight: bold;
    color: #ee0000;
    border-color: #ee0000;
}

.order .note {
    float: left;
    clear: both;
    color: #444;
    font-size: 11pt;
}

.order hr {
    float: left;
    width: 100%;
}

.order .cancel-order-button {
    color: #d80000;
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: -5px;
}