.toggle-cart-button {
    color: white;
    font-size: 16pt;
    background: #222;
    margin: 0 1em;
    white-space: nowrap;

    .toggle-cart-button i {
        margin: 0;
    }
}

.toggle-cart-button:hover i {
    color: $secondary-color;
}

.shopping-cart {
    color: black;
    float: left;
    width: 100%;
}

// Maybe not used anymore
.shopping-cart {
    h1 {
        font-size: 16pt;
        color: $primary-color;
    }

    h2 {
        font-size: 14pt;
        font-weight: normal;
        color: $primary-color;
        text-align: center;
        margin-top: 10px;
    }

    h3 {
        font-size: 14pt;
        font-weight: normal;
        color: $secondary-color;
        text-align: center;
        margin-top: 5px;
    }
}

.cart-nav aside {
    z-index: 1031 !important;
}

.cart-nav aside nav > div {
    height: 100%; 
    overflow-x: hidden;
    overflow-y: auto;
}

.cart-panel  hr {
    margin: 5px 0;
}

.cart-panel .body {
    max-height: 375px;
    overflow: auto;
}

.cart-panel .body p {
    margin: 0;
    font-size: 12pt;
    text-align: center;
}

.cart-item {
    position: relative;
}

.cart-item .product {
    display: flex;
    line-height: 30px;
    position: relative;
}

.cart-item .image-container {
    background-color: #888;
    background-size: cover;
    background-position: center center;
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
}

.cart-item .info {
    padding-left: 5px;
}

.cart-item .info > span {
    font-size: 0.9em;
}

.cart-item .options {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 36px;
}

.cart-item .options .counter button {
    font-size: 1.5em;
    padding: 0;
    // margin-right: 5px !important;
}

.cart-item .remove-button {
    white-space: nowrap;
    position: absolute;
    right: 0;
}

.cart-item .extras {
    width: 100%;
    font-size: 10pt;
    line-height: 30px;
}

.cart-item .extras span {
    display: inline-block;
    padding: 0 10px;
}

.cart-item .info p {
    width: 100%;
    display: block;
    float: left;
    margin: 0;
    text-align: left !important;
    white-space: nowrap;
}

.checkout-button {
    display: block;
    margin-top: 35px;
    margin-bottom: 5px;
    font-family: 'Noto Sans JP', sans-serif;
    margin-left: auto;
    margin-right: auto;
}

.gotopayment-button, .backtocart-button {
    border-radius: 25px !important;
    display: block;
    margin-top: 45px;
    margin-bottom: 25px;
    font-family: 'Noto Sans JP', sans-serif;
    margin-left: auto;
    margin-right: auto;
    background-color: #222;
}

.gotopayment-button:hover, .backtocart-button:hover {
    background-color: $primary-color;
}

.backtocart-button {
    margin-top: 20px;
    margin-bottom: 35px;
}

.checked-out-message {
    background-color: white;
}

.purchase-method-selector {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 15px 0 0 0;
    padding-bottom: 15px;
    
    .radio-container {
        flex-basis: 100px;
    }
}

.collection-point-selector {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .radio-container {
        flex-basis: 200px;
    }

    .address {
        margin-top: 5px;
    }
}

@media only screen and (max-width: $res0) {
    .toggle-cart-button {
        font-size:1em;
        left: 5px;
        bottom: 5px;
        top: auto;
    }

    .purchase-method-selector {
        margin-bottom: 5px;
    }

    .purchase-method-selector .radio-container {
        font-size: 10pt;
    }

    .cart-item .info p {
        font-size: 11pt;
    }
}

.requested-date-time {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.requested-date-time input {
    text-align: center;
}

.requested-date-time .time-picker {
    margin-left: 0.5em;
}

.login-or-register {
    font-size: 1em;
    text-align: center;
    margin-top: 2em;
}

.login-or-register .btn-link {
    padding: 0 0.2em;
    margin-top: -0.2em;
}

.payment-method {
    margin: 25px 0;

    .payment-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

/*.payment-method .payment-options */.radio-container {
    font-size: 0.8em;
    padding-top: 0.15em;
    flex-basis: 188px;
}

.shopping-cart .link-center {
    display: block;
    text-align: center;
    margin: 0.6em 0;
}

.agree-button {
    display: block !important;
    color: black;
    text-align: center;
}

.shopping-cart {
    .stepper {
        margin-top: 25px;
        background-color: #f8f8f8;
    }

    .cart-options {
        p {
            line-height: 1em;
        }

        button {
            background-color:  #222;
            color: white;
        }
        
        button:hover {
            background-color:  $primary-color;
        }

        // Delete Address Button
        .delete-button {
            background-color: #cc0000;
            padding-top: 3px;
            padding-bottom: 3px;
            margin-top: -8px;
            margin-right: 15px;
        }

        .payment-note {
            text-align: center;
        }
    }
}

.stripe {
    float: left;
    display: flex;
    justify-content: center;
    width: 100%;

    #root {
        display: flex;
        align-items: center;
    }

    body {
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        display: flex;
        justify-content: center;
        align-content: center;
        height: 100vh;
        width: 100vw;
    }

    form {
        width: 30vw;
        max-width: 765px;
        align-self: center;
        box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
        border-radius: 7px;
        // padding: 40px;
    }

    input {
        border-radius: 6px;
        margin-bottom: 6px;
        padding: 12px;
        border: 1px solid rgba(50, 50, 93, 0.1);
        max-height: 44px;
        font-size: 16px;
        width: 100%;
        background: white;
        box-sizing: border-box;
    }

    .result-message {
        line-height: 22px;
        font-size: 16px;
    }

    .result-message a {
        color: rgb(89, 111, 214);
        font-weight: 600;
        text-decoration: none;
    }

    .hidden {
        display: none;
    }

    #card-error {
        color: rgb(105, 115, 134);
        font-size: 16px;
        line-height: 20px;
        margin-top: 12px;
        text-align: center;
    }

    #card-element {
        border-radius: 4px 4px 0 0;
        padding: 12px;
        border: 1px solid rgba(50, 50, 93, 0.1);
        max-height: 44px;
        width: 100%;
        background: white;
        box-sizing: border-box;
    }

    #payment-request-button {
        margin-bottom: 32px;
    }

    /* Buttons and links */
    button {
        background: #222;
        font-family: Arial, sans-serif;
        color: #ffffff;
        border-radius: 0 0 4px 4px;
        border: 0;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        width: 100%;
    }

    button.new-card-button {
        padding: 8px 12px 5px 12px;
        border-radius: 3px;
        width: auto;
        margin-top: 15px;
        font-size: 13px;
    }

    button.delete-card {
        margin-top: 4px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        
        svg {
            width: 20px;
            height: 20px;
        }
    }

    button:hover {
        filter: contrast(115%);
        background-color: $primary-color;
    }

    button:disabled {
        opacity: 0.5;
        cursor: default;
    }

    /* spinner/processing state, errors */
    .spinner,
    .spinner:before,
    .spinner:after {
        border-radius: 50%;
    }

    .spinner {
        color: #ffffff;
        font-size: 22px;
        text-indent: -99999px;
        margin: 0px auto;
        position: relative;
        width: 20px;
        height: 20px;
        box-shadow: inset 0 0 0 2px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    .spinner:before,
    .spinner:after {
        position: absolute;
        content: "";
    }

    .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: #5469d4;
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: #5469d4;
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
    }

    @keyframes loading {
        0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        }
        100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        }
    }

    @media only screen and (max-width: 600px) {
        form {
            width: 80vw;
        }
    }
}