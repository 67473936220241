.header-avatar {
    width: 42px;
    cursor: pointer;
    margin-left: 0.7em;
}

.header-avatar .form-image {
    width: 42px;
    background-color: #d6d6d6;
}

.user-popover {
    position: absolute;
    padding: 5px;
    width: 200px;
    top: -5px;
    right: 5px;
    background-color: white;
    border: 1pt solid #d2d2d2;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
    border-radius: 2px;
    text-align: center;
}

.user-popover a {
    display: block;
    margin: 15px 0;
}

.user-popover h1 {
    font-size: 1em;
    width: 100%;
    padding-right: 42px;
    margin: 0px;
    margin-top: 1.2em;
    min-height: 32px;
    text-align: center;
    color: $primary-color;
    font-weight: bold;
    cursor: default;
}

.user-popover .logout-button {
    border: 1pt solid #bb0000;
    padding: 5px 15px;
}

.user-popover .logout-button i {
    margin-right: 6px;
    margin-top: -1px;
}

.toggle-quick-user-button {
    font-size: 1.2em !important;
}

.toggle-quick-user-button i {
    color: white;
}