.animated-preloader {
    font-size: 26px;
    margin-left: auto;
    margin-right: auto;
    width: 2em;
    height: 2em;
}

.animated-preloader .inner, .animated-preloader .outer {
    position: absolute;
    border-radius: 50%;
}

.animated-preloader .inner {
    background: $preloader-inner-color;
    opacity: 0.8;
    width: 0.8em;
    height: 0.8em;
    margin-top: 1em;
    margin-left: 1em;
    transform: translate(-50%, -50%);
    animation: animate-preload-inner 2s infinite;
}

.animated-preloader .outer {
    background: $preloader-outer-color;
    width: 2em;
    height: 2em;
    transform-origin: center center;
    animation: animate-preload-outer 2s infinite;
}

@keyframes animate-preload-outer {
    from { opacity: 1; }
    to { opacity: 0; }
    0% { transform: scale(0.0); }
    100% { transform: scale(1.0); }
}

@keyframes animate-preload-inner {
    0% { transform: translate(-50%, -50%) scale(0.9); }
    25% { transform: translate(-50%, -50%) scale(1.0); }
    50% { transform: translate(-50%, -50%) scale(1.1); }
    75% { transform: translate(-50%, -50%) scale(1.0); }
    100% { transform: translate(-50%, -50%) scale(0.9); }
}